<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-dialog v-model="previewRecordPrompt" persistent max-width="65%">
        <jcom-gnote-preview
          v-if="previewRecordPrompt"
          :pageTitle="previewRecordPageTitle"
          :previewRecordPrompt="previewRecordPrompt"
          :recordData="recordData"
          @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        ></jcom-gnote-preview>
      </v-dialog>

      <v-card-text>
        <v-container>
          <br />

          <v-row wrap>
            <!-- <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="addRecordPrompt = true"
                color="#8950FC"
                elevation="30"
                shaped
                large
                class="font-size-h6 mr-3 my-3 white--text"
              >
                &nbsp; Create &nbsp;
              </v-btn>
              <v-btn
                large
                @click.prevent="refreshPageData"
                color="#a4c639"
                elevation="30"
                shaped
                class="font-size-h6 mr-3 my-3 white--text"
                >&nbsp; Refresh &nbsp;
              </v-btn>
            </v-col> -->
          </v-row>

          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0">
            <v-form ref="form" v-model="valid" lazy-validation id="createForm">
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6><span class="text-danger">*</span> Zone</h6>
                  <v-autocomplete
                    @change="getTableNameOptions"
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>Table Name <small>(Optional)</small></h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="TableNameOptions"
                    :rules="TableNameRules"
                    v-model="TableName"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>From Date <small>(optional)</small></h6>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="FromDate"
                        label="From Date"
                        hint="Select a From Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="FromDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>To Date <small>(optional)</small></h6>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="ToDate"
                        label="To Date"
                        hint="Select a To Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ToDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                    @click="searchForm"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <div
                  class="d-flex justify-content-center mb-3"
                  v-if="ProgessStart == 1"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    label="We are fetching details, please wait..."
                  ></b-spinner>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">
                  {{ tableData1.length }} records found.
                </h3>
                <v-btn
                  v-if="DownloadFlag"
                  color="#8a2be2"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.SaleStatusTxt="{ item }">
                    <v-chip :color="item.StatusColor" draggable dark>{{
                      item.SaleStatusTxt
                    }}</v-chip>
                  </template>

                  <template v-slot:item.MemberImagePath="{ item }">
                    <img width="100" height="100" :src="item.MemberImagePath" />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="item.PreviewFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="red"
                          @click="deleteAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span> Delete </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

import JcomGnotePreview from "@/view/pages/erp/jcom/jcom-gnote/JcomGnotePreview.vue";

export default {
  mixins: [common],
  components: {
    JcomGnotePreview,
  },
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 12,
      JciYearCodeOptions: [],

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],

      TableNameRules: [],
      TableName: "",
      TableNameOptions: [],

      RoleId: "",

      ResultFlag: 0,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      previewRecordPageTitle: "Jcom G-Note Preview",

      recordData: {},
      selectedData: {},

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.FromDate = todayDate;
        this.ToDate = todayDate;

        this.getZoneCodeOptions();
      }
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key])
              return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    IdCardType: function () {
      console.log("watch IdCardType");
      var IdCardType = this.IdCardType;
      console.log({ IdCardType });
      this.tableData1 = [];
      this.selected1 = [];
      this.DownloadMemberPhotos = 0;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "Gnote Date <b> " + tr.SaleDateTxt + "</b>";
        htmlTxt += "<br/>Gnote Amount <b> " + tr.SaleAmount + "</b>";
        htmlTxt += "<br/>Business Type <b> " + tr.SaleBusinessTypeTxt + "</b>";
        htmlTxt += "<br/>Payment Type <b> " + tr.SalePaymentTypeTxt + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteData() {
      console.log("deleteData is called");

      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/sale/destroy";

        var upload = {
          UserInterface: 1,
          JcomLead: tr.JcomLeadId,
          FromTable: tr.FromTableId,
          FromMember: tr.FromMemberId,
          ToTable: tr.ToTableId,
          ToMember: tr.ToMemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.selectedData = {};
              thisIns.selectedData = {};
              thisIns.selected = [];
              thisIns.searchForm();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);
      if (tableId == 1) {
        var n1 = this.AllDesignation.length;
        console.log("n1=" + n1);
        if (n1 < 5) {
          this.AllDesignation.push({
            year: "",
            level: "",
            position: "",
          });
        }
      }

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        if (n1 < 30) {
          this.NewMembers.push({
            MemberName: "",
            MemberEmail: "",
            MemberMobileNo: "",
            MemberAddress: "",
            MemberCity: "",
          });
        }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_gnote",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFormFieldOptions() {
      console.log("getFormFieldOptions called");
      // this.getContributionTypeIdOptions();
      this.getZoneCodeOptions();
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/jcom/table/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableNameOptions() {
      console.log("getTableNameOptions called");
      var selectbox1_source = "TableName";
      var selectbox1_destination = "TableNameOptions";
      var selectbox1_url = "api/jcom/table/options";

      var Zone = this.ZoneCode;

      var selectbox1_conditions_array = {
        UserInterface: 2,
        Zone: Zone,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");

      this.resetMessageTxt();

      var ZoneCode = this.ZoneCode;
      var TableName = this.TableName;
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;

      console.log(
        "ZoneCode=" +
          ZoneCode +
          ", TableName=" +
          TableName +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (result && ZoneCode != "") {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/sale/lists";
        var upload = {
          UserInterface: 1,
          Zone: ZoneCode,
          FromTable: TableName,
          FromDate: FromDate,
          ToDate: ToDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            table_content = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = table_content.TableOptions;
            records = table_content.TableData;

            if (flag == 1) {
              thisIns.tableColumns1 = table_content.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.tableData1 = records;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        var message = "Kindy select the zone and then submit";
        this.sweetAlert("error", message, true);
        // this.toast("error", message);
      }
    },
    updateConfirmAlert() {
      this.resetMessageTxt();

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (n1 > 0 && result) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            var upload = {
              Year: this.JciYearCode,
              Members: selected,
            };
            this.updateRecords(upload);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!result) {
          message += "Kindly fill the required fields. ";
        }
        if (n1 == 0) {
          message += "Atleast select one member to update. ";
        }
        this.toast("error", message);
      }
    },
    updateRecords(upload) {
      console.log("updateRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/issued-cards-update";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableData1 = [];
            thisIns.searchForm();
            thisIns.sweetAlert("success", output, true);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>
